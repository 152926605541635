import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/_layout";
import MarkdownContent from "@components/markdown/markdown-content";
import StatusIcon from "@components/status-icon";
import sadIcon from "@assets/feathericon/frowning.svg";
import "./error.scss";

export default function ErrorLayout({
  data: { mdx: { frontmatter: { title }, body: content } },
  pageContext
}) {
  return (
    <Layout title={title} {...pageContext}>
      <main id="main" className="ErrorPage">
        <div className="ErrorPage__text">
          <StatusIcon type="error" iconSrc={sadIcon} />

          <MarkdownContent>
            {content}
          </MarkdownContent>
        </div>

      </main>
    </Layout>
  )
};

export const pageQuery = graphql`
query Error($id: String!) {
  mdx(id: { eq: $id }) {
      frontmatter {
        title,
      }
      body
    },
  }
`;